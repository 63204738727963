import { postDOM } from 'utils/mounting'

let loaded = false

async function setup () {
    // console.log('Huraaa')
    if (loaded) return
    // console.log('Jedem')

    const { default: Typewriter } = await import(/* webpackPrefetch: true */ 'typewriter-effect/dist/core')

    // new Typewriter('#homepage-search', {
    //     strings: ['Charles bridge', 'Prague castle'],
    //     autoStart: true,
    //     loop: true,
    // })

    var input = document.getElementById('homepage_search')

    if (!input) return

    new Typewriter(input, {
        strings: [
            'What would you like?',
            'Charles bridge',
            'King\'s road',
            'Gothic architecture',
            'Old town\'s sqare',
            'Jazz music',
            'Astronomical clock',
            'Baroque architecture',
            'Classical music',
            'Guided tour',
            'Art deco',
            'Prague castle',
        ],
        loop: true,
        autoStart: true,
        //cursor: true,
        delay: 25,
        deleteSpeed: 10,
        pauseFor: 2000,
        //onCreateTextNode: customNodeCreator,
        //onRemoveNode: onRemoveNode,
    })


    loaded = true
}

postDOM(setup)